const payments = [
  {
    text: "3",
    enable: true,
    merchantId: 18,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 1200000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "4",
    enable: true,
    merchantId: 19,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 2500000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "8",
    enable: true,
    merchantId: 20,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 9",
    enable: true,
    merchantId: 21,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "11",
    enable: true,
    merchantId: 22,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "9",
    enable: true,
    merchantId: 23,
    type: "UPI",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "11",
    enable: true,
    merchantId: 24,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "12",
    enable: true,
    merchantId: 25,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "decenpay",
    enable: true,
    merchantId: 26,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "globalpay",
    abridgeText: "G",
    enable: true,
    merchantId: 32,
    type: "BANKCHOOSE",
    img: [
      "../assets/images/home/jazz.png",
      "../assets/images/home/easypais.png",
    ],
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "mmpay",
    abridgeText: "M",
    enable: true,
    merchantId: 27,
    type: "BANKCHOOSE",
    img: [
      "../assets/images/home/jazz.png",
      "../assets/images/home/easypais.png",
      "../assets/images/home/bank.png",
    ],
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "decenpkpay",
    abridgeText: "D",
    enable: true,
    merchantId: 29,
    type: "BANKNAME",
    img: [
      "../assets/images/home/jazz.png",
      "../assets/images/home/easypais.png",
    ],
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },

  },
];

const purses = {
  27: [
    {
      icon: "../assets/images/home/jazz.png",
      text: "JAZZCASH"
    },
    {
      icon: "../assets/images/home/easypais.png",
      text: "EASYPAISA"
    },
    {
      icon: "../assets/images/home/bank.png",
      text: "BANK"
    },
  ],
  29: [
    {
      icon: "../assets/images/home/jazz.png",
      text: "JAZZCASH"
    },
    {
      icon: "../assets/images/home/easypais.png",
      text: "EASYPAISA"
    },
  ],
  32: [
    {
      icon: "../assets/images/home/jazz.png",
      text: "JAZZCASH"
    },
    {
      icon: "../assets/images/home/easypais.png",
      text: "EASYPAISA"
    },
    {
      icon: "../assets/images/home/bank.png",
      text: "BANK"
    },
  ]
}

const names = [
  {
    nameText: "Jazz cash",
  },
  {
    nameText: "Easypaisa",
  },
];

const codes = [
  // {
  //   codeId : "JAZZCASH",
  //   codeText : "JAZZCASH",
  // },
  // {
  //   codeId : "EASYPAISA",
  //   codeText : "EASYPAISA",
  // },
  {
    codeId: "001",
    codeText: "ALBARAKA ISLAMIC BANK",
    codeMsg: "ALBARAKA_ISLAMIC_BANK",
  },
  {
    codeId: "002",
    codeText: "ALLIED BANK LIMITED",
    codeMsg: "ALLIED_BANK_LIMITED",
  },
  {
    codeId: "003",
    codeText: "APNA MICRO FINANCE BANK",
    codeMsg: "APNA_MICRO_FINANCE_BANK",
  },
  {
    codeId: "004",
    codeText: "ASKARI BANK LIMITED",
    codeMsg: "ASKARI_BANK_LIMITED",
  },
  {
    codeId: "005",
    codeText: "BANK AL HABIB LIMITED",
    codeMsg: "BANK_AL_HABIB_LIMITED",
  },
  {
    codeId: "006",
    codeText: "BANK ALFALAH LIMITED",
    codeMsg: "BANK_ALFALAH_LIMITED",
  },
  {
    codeId: "007",
    codeText: "BANK ISLAMI PAKISTAN LIMITED",
    codeMsg: "BANK_ISLAMI_PAKISTAN_LIMITED",
  },
  {
    codeId: "008",
    codeText: "BANK OF KHYBER",
    codeMsg: "BANK_OF_KHYBER",
  },
  {
    codeId: "009",
    codeText: "CITI BANK NA",
    codeMsg: "CITI_BANK_NA",
  },
  {
    codeId: "010",
    codeText: "DUBAI ISLAMIC BANK PAKISTAN LIMITED",
    codeMsg: "DUBAI_ISLAMIC_BANK_PAKISTAN_LIMITED",
  },
  {
    codeId: "011",
    codeText: "FAYSAL BANK LIMITED",
    codeMsg: "FAYSAL_BANK_LIMITED",
  },
  {
    codeId: "012",
    codeText: "FINCA MICRO FINANCE BANK",
    codeMsg: "FINCA_MICRO_FINANCE_BANK",
  },
  {
    codeId: "013",
    codeText: "FIRST WOMEN BANK LIMITED",
    codeMsg: "FIRST_WOMEN_BANK_LIMITED",
  },
  {
    codeId: "014",
    codeText: "HABIB BANK LIMITED",
    codeMsg: "HABIB_BANK_LIMITED",
  },
  {
    codeId: "015",
    codeText: "HABIB METROPOLITAN BANK LIMITED",
    codeMsg: "HABIB_METROPOLITAN_BANK_LIMITED",
  },
  {
    codeId: "016",
    codeText: "JS BANK LIMITED",
    codeMsg: "JS_BANK_LIMITED",
  },
  {
    codeId: "017",
    codeText: "MCB BANK LIMITED",
    codeMsg: "MCB_BANK_LIMITED",
  },
  {
    codeId: "018",
    codeText: "MCB ISLAMIC",
    codeMsg: "MCB_ISLAMIC",
  },
  {
    codeId: "019",
    codeText: "MEEZAN BANK",
    codeMsg: "MEEZAN_BANK",
  },
  {
    codeId: "020",
    codeText: "NATIONAL BANK OF PAKISTAN",
    codeMsg: "NATIONAL_BANK_OF_PAKISTAN",
  },
  {
    codeId: "021",
    codeText: "NRSP MICRO FINANCE BANK",
    codeMsg: "NRSP_MICRO_FINANCE_BANK",
  },
  {
    codeId: "022",
    codeText: "NIB BANK LIMITED",
    codeMsg: "NIB_BANK_LIMITED",
  },
  {
    codeId: "023",
    codeText: "SAMBA BANK LIMITED",
    codeMsg: "SAMBA_BANK_LIMITED",
  },
  {
    codeId: "024",
    codeText: "SILK BANK LIMITED",
    codeMsg: "SILK_BANK_LIMITED",
  },
  {
    codeId: "025",
    codeText: "SINDH BANK LIMITED",
    codeMsg: "SINDH_BANK_LIMITED",
  },
  {
    codeId: "026",
    codeText: "SONERI BANK LIMITED",
    codeMsg: "SONERI_BANK_LIMITED",
  },
  {
    codeId: "027",
    codeText: "STANDARD CHARTERED BANK LTD",
    codeMsg: "STANDARD_CHARTERED_BANK_LTD",
  },
  {
    codeId: "028",
    codeText: "SUMMIT BANK LIMITED",
    codeMsg: "SUMMIT_BANK_LIMITED",
  },
  {
    codeId: "029",
    codeText: "TELENOR MICRO FINANCE BANK",
    codeMsg: "TELENOR_MICRO_FINANCE_BANK",
  },
  {
    codeId: "030",
    codeText: "THE BANK OF PUNJAB",
    codeMsg: "THE_BANK_OF_PUNJAB",
  },
  {
    codeId: "031",
    codeText: "U MICRO FINANCE BANK",
    codeMsg: "U_MICRO_FINANCE_BANK",
  },
  {
    codeId: "032",
    codeText: "UNITED BANK LIMITED",
    codeMsg: "UNITED_BANK_LIMITED",
  },
];

export default {
  payments: payments.filter((payment) => payment.enable),
  withdraw_type: "UPI", // BANKCARD, MOBILE_MONEY
  codes: codes,
  names: names,
  purses: purses,
};
